import { EndCustomer } from "@api/graphql/generated/generated-types";
import React from "react";
import EditCustomerForm from "@src/endcustomers/components/EditCustomerForm";

export interface CustomerWidgetState {
  data: {
    endCustomer: EndCustomer | null | undefined;
  };
  loading: boolean;
  error: any;
}

type Props = {
  endCustomer: EndCustomer | null | undefined;
};

function CustomerWidget({ endCustomer }: Props) {
  if (endCustomer) {
    return (
      <div className="p-3 h-full bg-white dark:bg-gray-800 border rounded-2xl">
        <span className="font-semibold mb-2">Customer</span>
        <div className="rounded-lg border shadow-md p-2 pt-2">
          <EditCustomerForm endCustomer={endCustomer} maxWidth={"100%"} />
        </div>
      </div>
    );
  }
  return null;
}

export default CustomerWidget;
